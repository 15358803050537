const PossibleProperty = {
    LuaVersion: {
        Type: "string",
        PossibleValue: ["Lua51", "LuaU"],
        NumberLimit: [],
        Default: "Lua51"
    },
    PrettyPrint: {
        Type: "boolean",
        PossibleValue: [true, false],
        NumberLimit: [],
        Default: false
    },
    VarNamePrefix: {
        Type: "string",
        PossibleValue: [],
        NumberLimit: [],
        Default: "Prometheus"
    },
    NameGenerator: {
        Type: "string",
        PossibleValue: ["Mangled", "MangledShuffled", "II", "Number"],
        NumberLimit: [],
        Default: "MangledShuffled"
    },
    Seed: {
        Type: "number",
        PossibleValue: [],
        NumberLimit: [],
        Default: Math.floor(Math.random() * 1000000000)
    },
}

const PossibleStep = {
    WrapInFunction: {
        Iterations: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [],
            Default: 0
        }
    },
    Vmify: {},
    SplitStrings: {
        Treshold: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, 1], // 0 <= x <= 1
            Default: 0.5
        },
        MinLength: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, Infinity], // x > 0
            Default: 5
        },
        MaxLength: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, Infinity], // x >= MinLength
            Default: 10
        },
        ConcatenationType: {
            Type: "string",
            PossibleValue: ["strcat", "table", "custom"],
            NumberLimit: [],
            Default: "strcat"
        },
        CustomFunctionType: {
            Type: "string",
            PossibleValue: ["global", "local", "inline"],
            NumberLimit: [],
            Default: "global"
        },
        CustomLocalFunctionsCount: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, Infinity], // x > 0
            Default: 5
        }
    },
    ProxifyLocals: {
        LiteralType: {
            Type: "string",
            PossibleValue: ["dictionary", "number", "string", "any"],
            NumberLimit: [],
            Default: "dictionary"
        }
    },
    EncryptStrings: {},
    ConstantArray: {
        Threshold: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, 1], // 0 <= x <= 1
            Default: 0.2
        },
        StringsOnly: {
            Type: "boolean",
            PossibleValue: [true, false],
            NumberLimit: [],
            Default: false
        },
        Shuffle: {
            Type: "boolean",
            PossibleValue: [true, false],
            NumberLimit: [],
            Default: false
        },
        Rotate: {
            Type: "boolean",
            PossibleValue: [true, false],
            NumberLimit: [],
            Default: false
        },
        LocalWrapperTreshold: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, 1], // 0 <= x <= 1
            Default: 0.1
        },
        LocalWrapperCount: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, Infinity], // x > 0
            Default: 1
        },
        LocalWrapperArgCount: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, Infinity], // x > 0
            Default: 1
        },
        MaxWrapperOffset: {
            Type: "number",
            PossibleValue: [],
            NumberLimit: [0, Infinity], // x > 0
            Default: 1
        }
    }
}

export { PossibleProperty, PossibleStep }