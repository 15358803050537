<template>
  <div id="content">
    <div id="prometheus-config">
      <p class="header t t-white">{{ PrometheusConfig.NameAndVersion }} Web</p>
      <div id="info-section" v-if="PrometheusPresets.Custom != undefined">
        <div id="steps-property">
          <div class="step-wrapper special-bg">
            <p class="step-title t t-white">Select preset</p>
            <div class="divider"></div>
            <select class="property-input" ref="presetSelect" @change="UpdatePreset()">
              <option v-for="(value, key) in PrometheusPresets" :key="key">{{ key }}</option>
            </select>
          </div>
          <div class="step-wrapper special-bg">
            <p class="step-title t t-white">Add New Step</p>
            <div class="divider"></div>
            <div class="selector-with-btn">
              <select class="property-input" ref="stepSelect">
                <option v-for="(value, key) in PossibleStep" :key="key">{{ key }}</option>
              </select>
              <button @click="AddNewStep()">Add</button>
            </div>
          </div>
          <div class="step-wrapper special-bg">
            <button @click="StartObfuscator()" class="obfuscate-btn">Obfuscate</button>
          </div>
          <div class="step-wrapper">
            <p class="step-title t t-white">Settings</p>
            <div class="divider"></div>
            <div class="propetry" v-for="(value, key) in PossibleProperty" :key="key">
              <div class="property-name t t-white">{{ key }}</div>
              <input v-if="value.PossibleValue.length <= 0 | value.Type == 'boolean'" class="property-input"
                :type="ConvertInputValueType(value)" v-model="PrometheusPresets.Custom[key]" :min="value.NumberLimit[0]"
                :max="value.NumberLimit[1]" step="0.1"
                :checked="ConvertInputValue(value, PrometheusPresets.Custom[key])" />
              <select v-if="value.PossibleValue.length > 0 & value.Type != 'boolean'" class="property-input"
                v-model="PrometheusPresets.Custom[key]">
                <option v-for="possibleValue in value.PossibleValue" :key="possibleValue">{{ possibleValue }}</option>
              </select>
            </div>
          </div>
          <div class="step-wrapper" v-for="(step, index) in PrometheusPresets.Custom.Steps" :key="index">
            <p class="step-title t t-white">{{ step.Name }}<button class="remove-button"
                @click="RemoveStep(index)">Remove</button></p>
            <div class="divider"></div>
            <div class="propetry" v-for="(value, key) in PossibleStep[step.Name]" :key="key">
              <div class="property-name t t-white">{{ key }}</div>
              <input v-if="value.PossibleValue.length <= 0 | value.Type == 'boolean'" class="property-input"
                :type="ConvertInputValueType(value)" :min="value.NumberLimit[0]" :max="value.NumberLimit[1]" step="0.1"
                :checked="ConvertInputValue(value, step.Settings[key])" v-model="step.Settings[key]" />
              <select v-if="value.PossibleValue.length > 0 & value.Type != 'boolean'" class="property-input"
                v-model="step.Settings[key]">
                <option v-for="possibleValue in value.PossibleValue" :key="possibleValue">{{ possibleValue }}</option>
              </select>
            </div>
          </div>
        </div>
        <!-- <output-terminal id="output-terminal" ref="outputTerminal" :lines="[]"></output-terminal> -->
        <terminal-logs id="output-terminal" ref="outputTerminal" :lines="ObfuscatorLogs"></terminal-logs>
      </div>
    </div>
    <div class="divider"></div>
    <div class="code-section">
      <textarea id="code-input" placeholder="Paste your script here"></textarea>
      <pre class="language-lua code-output">
        <code>{{ this.ObfuscatedScript }}</code>
      </pre>
    </div>
  </div>
</template>

<script>
import { loadScript } from "vue-plugin-load-script";
import { PossibleProperty, PossibleStep } from './PresetInfo'
import TerminalLogs from './components/terminal'

export default {
  name: 'App',
  // beforeCreate() {
  //   LoadScript('https://github.com/fengari-lua/fengari-web/releases/download/v0.1.4/fengari-web.js')
  // },
  components: {
    TerminalLogs,
  },
  mounted() {
    loadScript("https://github.com/fengari-lua/fengari-web/releases/download/v0.1.4/fengari-web.js")
      .then(() => {
        let fengari = window.fengari;
        fengari.load(`
          JS = require "js"
          Window = js.global
          Logs = {}
          LuaJson = require("json")
          arg = {}
          package.path = "Prometheus/?.lua;Prometheus/?/init.lua";
          Prometheus = require("src.prometheus");
        `)()
        fengari.load(`
          table.insert(Logs, "ClearAll")
          Prometheus.Logger.debugCallback = function(message)
            table.insert(Logs, { text = message, type = 'Debug' })
          end
          Prometheus.Logger.logCallback = function(message)
            table.insert(Logs, { text = message, type = 'Log' })
          end
          Prometheus.Logger.warnCallback = function(message)
            table.insert(Logs, { text = message, type = 'Warn' })
          end
          Prometheus.Logger.errorCallback = function(message)
            table.insert(Logs, { text = message, type = 'Error' })
          end
        `)()
        this.PrometheusPresets = JSON.parse(fengari.load(`
          return LuaJson.encode(Prometheus.Presets)
        `)())
        this.PrometheusConfig = JSON.parse(fengari.load(`
          return LuaJson.encode(require("src.config"))
        `)())

        this.FillStepEmptyData()
        this.UpdatePreset(false, 'Weak')

        const outputTerminal = this.$refs.outputTerminal
        setInterval(() => {
          const logs = JSON.parse(fengari.load(`
            return LuaJson.encode(Logs)
          `)())
          for (let i = 0; i < logs.length; i++) {
            if (logs[i] == 'ClearAll') {
              this.ObfuscatorLogs = []
            } else {
              this.ObfuscatorLogs.push(logs[i])
            }
          }
          outputTerminal.scrollTop = outputTerminal.scrollHeight
        }, 100);
      })
    // .catch(error => {
    //   console.log("Script load error: ", error);
    // });
  },
  data() {
    return {
      PrometheusConfig: {},
      PrometheusPresets: {
        Custom: {
          Steps: {}
        }
      },
      PossibleProperty: PossibleProperty,
      PossibleStep: PossibleStep,
      Obfuscating: false,
      ObfuscatorLogs: [],
      ObfuscatedScript: 'Obfuscated script will appear here',
    }
  },
  methods: {
    ConvertInputValueType(value) {
      return value.Type == 'string' ? 'text' : value.Type == 'number' ? 'number' : value.Type == 'boolean' ? 'checkbox' : value.Type
    },
    ConvertInputValue(value, useValue) {
      if (value == undefined) return undefined
      // console.log(useValue)
      // useValue = useValue == undefined ? value.Default : useValue
      // return value.Type == 'string' ? useValue : value.Type == 'number' ? useValue : value.Type == 'boolean' ? Number(value.Type) : useValue
      return useValue
    },
    GetCurrentPresetName() {
      // Compare the current preset with all presets and return the name of the preset
      for (const preset in this.PrometheusPresets) {
        //Don't use json to compare because the order of the properties is not the same
        if (preset == "Custom") continue
        if (JSON.stringify(this.PrometheusPresets[preset]) == JSON.stringify(this.PrometheusPresets.Custom)) {
          return preset
        }
      }
      return "Custom"
    },
    UpdatePreset(nameOnly, forceSetName) {
      if (forceSetName) {
        this.$refs.presetSelect.value = forceSetName
      }
      const useName = forceSetName ? forceSetName : this.$refs.presetSelect.value
      // console.log(this.PrometheusPresets[this.CurrentPreset])
      if (!nameOnly) {
        this.PrometheusPresets.Custom = Object.assign({}, this.PrometheusPresets[useName])
      }
      this.$refs.presetSelect.value = this.GetCurrentPresetName()

      // console.log(this.CurrentPreset)
    },
    RemoveStep(index) {
      this.PrometheusPresets.Custom.Steps.splice(index, 1)
    },
    UpdatePresetValue(key, source, value) {
      //Convert the type for boolean values, use the value from the PossibleStep. Value may be 1 or 0 due to checkbox
      if (source == 'property') {
        const valueType = PossibleProperty[key].Type
        if (valueType == "number") value = Number(value)
        this.PrometheusPresets.Custom[key] = value
      } else {
        const valueType = PossibleStep[this.PrometheusPresets.Custom.Steps[source].Name][key].Type
        if (valueType == "number") value = Number(value)
        this.PrometheusPresets.Custom.Steps[source].Settings[key] = value
      }
      this.UpdatePreset(true)
    },
    FillStepEmptyData() {
      for (const preset in this.PrometheusPresets) {
        if (this.PrometheusPresets[preset].Steps == undefined) this.PrometheusPresets[preset].Steps = {}
        // this.PrometheusPresets[preset].Steps , i in .Steps
        for (const stepIndex in this.PrometheusPresets[preset].Steps) {
          const stepData = this.PrometheusPresets[preset].Steps[stepIndex]
          for (const property in PossibleStep[stepData.Name]) {
            if (stepData.Settings[property] == undefined) {
              this.PrometheusPresets[preset].Steps[stepIndex].Settings[property] = PossibleStep[stepData.Name][property].Default
            }
          }
        }
      }
    },
    AddNewStep(stepName) {
      if (stepName == undefined) {
        stepName = this.$refs.stepSelect.value
      }
      this.PrometheusPresets.Custom.Steps.push({
        Name: stepName,
        Settings: {}
      })
      this.FillStepEmptyData()
      this.UpdatePreset(true)
    },
    StartObfuscator() {
      if (this.Obfuscating) return
      let fengari = window.fengari;
      this.Obfuscating = true
      this.ObfuscatedScript = fengari.load(`
        local success, err = pcall(function()
          local pipeline = Prometheus.Pipeline:fromConfig(LuaJson.decode('${JSON.stringify(this.PrometheusPresets.Custom)}'))

          -- Apply the obfuscation and print the result
          table.insert(Logs, "ClearAll")
          return pipeline:apply(Window.document:getElementById("code-input").value)
        end)
        if not success then
          table.insert(Logs, {Type = "error", Message = err})
          warn(err)
        end
        return err
      `)()
      this.Obfuscating = false
    },
    ClearLogs() {
      this.ObfuscatorLogs = []
    },
    AddLog(log) {
      this.ObfuscatorLogs.push(log)
    }
  },
  computed() {
    return {
      CurrentPreset() {
        return this.GetCurrentPresetName()
      }
    }
  }
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  background-color: #1b1c1f;
  display: flex;
  flex-direction: column;
}

#prometheus-config {
  padding: 20px;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#info-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

#output-terminal {
  height: 100%;
  width: 50%;
  min-width: 400px;
  pointer-events: none;
}

#regular-property,
#steps-property {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border: 1px solid white;
  padding: 10px;
  border-radius: 10px;
  background-color: #2b2c2f;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#steps-property {
  width: 450px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}

#regular-property {
  min-width: 300px;
}

.selector-with-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.selector-with-btn>select {
  width: 80%;
}

.step-wrapper {
  background-color: #313131;
  border: 1px solid white;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  padding: 10px;
}

.special-bg {
  background-color: #202841;
}

.terminal-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-button {
  margin-left: auto;
}

.propetry {
  width: 300px;
}

.property-input {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.obfuscate-btn {
  background-color: #235831;
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  font-size: 1.5em;
}

.t-white {
  color: white;
}

.step-title {
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: white;
  text-align: left;
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
}

.code-section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.code-output {
  width: 45%;
  height: 70%;
  margin: 0 auto;
}

#code-input {
  width: 45%;
  height: 70%;
  margin: 0 auto;
}

.t {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5em;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.header {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 2em;
  width: 100%;
  padding: 10px;
}

.divider {
  width: 100%;
  margin: 10px 0px;
  height: 1px;
  background-color: #ffffff93;
}

input {
  border: 1px solid #ffffff;
  background-color: #1b1c1f;
  color: #ffffff;
  padding: 4px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  width: 250px;
  text-align: center;
}

select {
  border: 1px solid #ffffff;
  background-color: #1b1c1f;
  color: #ffffff;
  padding: 4px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  width: 250px;
  text-align: center;
}

option {
  background-color: #1b1c1f;
  color: #ffffff;
  padding: 4px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 1em;
  width: 250px;
  text-align: center;
}

button {
  border: 1px solid #ffffff;
  background-color: #1b1c1f;
  color: #ffffff;
  padding: 4px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.7em;
  min-width: 60px;
  text-align: center;
  cursor: pointer;
}
</style>

<style>
body {
  overflow-x: hidden;
}
</style>
