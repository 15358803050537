import { createApp } from "vue";
import VueCodeHighlight from 'vue-code-highlight';
import LoadScript from "vue-plugin-load-script";
import App from './App.vue'

const app = createApp(App);
app.use(LoadScript);
app.use(VueCodeHighlight);

app.mount("#app");
window.app = app;