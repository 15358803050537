<template>
    <div class="terminal">
        <div class="terminal-body">
            <div class="terminal-body-content">
                <!-- <div class="terminal-body-content-line" v-for="line in lines" :key="line">{{ line }}</div> -->
                <div class="terminal-body-content-line" v-for="line in lines" :key="line.text" :style="{ color: TypeColors[line.type] }">[Prometheus] [{{ line.type }}]: {{ line.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>

const TypeColors = {
    Debug: '#00ffff',
    Log: '#00ff00',
    Warn: '#ffff00',
    Error: '#ff0000',
};

export default {
    name: 'terminal-logs',
    props: {
        lines: {
            type: Array,
            default: () => [{ text: 'Hello World', type: 'Warn' }],
        }
    },
    data() {
        return {
            TypeColors,
        };
    },
};
</script>

<style scoped>
.terminal {
    background-color: rgb(22, 22, 22);
    color: #fff;
    font-family: monospace;
    font-size: 14px;
    height: 100%;
    width: 100%;
}

.terminal-body {
    height: 100%;
    overflow: auto;
    padding: 10px;
}

.terminal-body-content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

</style>